<template>
<div class="container">
  <el-carousel class="banner" v-if="banners.length" height="475px" :style="banners.length > 0 ? 'position: relative; top:-90px;':''">
    <el-carousel-item v-for="item in banners" :key="item.id">
      <!-- <a :href="item.href" :title="item.title"> -->
      <img class="img" :src="item.src" :alt="item.title">
    </el-carousel-item>
  </el-carousel>

  <div class="title">
    尊享理财体验
  </div>
  <div class="box-sub-title">
    <div class="sub-title">
      众惠基金以客户长期收益获取为目标，利用完善的风控体系，致力于通过与客户的长期往来关系，为高净值客户甄选优质的高端理财产品，提供一站式高端理财服务。
    </div>
  </div>

  <div class="boxA vw">
    <div v-for="item in boxA" :key="item.id" class="boxA-item">
      <el-image class="left" :src="item.url" fit="contain" />
      <div class="right">
        <div class="top">
          {{ item.title }}
        </div>
        <div class="bottom">
          {{ item.subTitle }}
        </div>
      </div>
    </div>
  </div>
  <!-- 尊享贵宾理财服务 -->
  <div class="boxB" v-if="false">
    <div class="title">
      尊享贵宾理财服务
    </div>
    <div class="boxB-list">
      <div v-for="item in boxB" :key="item.id" class="boxB-item">
        <el-image class="left" :src="item.url" fit="contain" />
        <div class="right">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>

  <!-- 私募交易流程 -->

  <div class="private-trade">
    <div class="title-box">
      <div class="title">
        高端理财产品交易流程
      </div>
      <div class="box-list">
        <div v-for="item in boxC" :key="item.id" class="box-item">
          <div class="top">
            <el-image class="A" :src="item.url" fit="contain" />
            <div class="B">
              {{ item.title }}
            </div>
            <el-image class="C" :src="item.nextFlag" fit="contain">
              <div slot="error" class="image-slot" />
            </el-image>
          </div>
          <div class="bottom">
            {{ item.subTitle }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="hight">
    <div class="title">
      高端理财推荐
    </div>
    <div class="list-box">
      <div v-for="item in dataList" :key="item.fundCode" class="box-item">
        <div class="risk">
          <div class="A" v-if="item.risk">
            {{ item.risk }}
          </div>
          <div class="B" v-if="item.fundType">
            {{ item.fundType }}
          </div>
        </div>
        <div class="code">
          基金代码 {{ item.fundCode }}
        </div>
        <div class="name">
          <router-link :to="{name:'HighFinancialDetail',params:{fundCode:item.fundCode}}">{{ item.fundName }}</router-link>
        </div>
        <div class="recent">
          {{item.incomeYear?'近一年涨幅':'成立以来涨幅'}}
        </div>
        <div v-if="item.incomeYear" class="img-box">
          <div :class="['left green']" v-if="item.incomeYear=='--'">
            {{ item.incomeYear }}
          </div>
          <div :class="['left',item.incomeYear<0?'green':'red']" v-else>
            {{ item.incomeYear ||'--'}}
            <small v-if="item.incomeYear">%</small>
          </div>
          <el-image class="right" :src="require('./images/blur.png')" fit="contain" />
        </div>
        <div v-else class="img-box">
          <div :class="['left green']" v-if="item.incomeSince=='--'">
            {{ item.incomeSince }}
          </div>
          <div :class="['left',item.incomeSince<0?'green':'red']" v-else>
            {{ item.incomeSince ||'--'}}
            <small v-if="item.incomeSince">%</small>
          </div>
          <el-image class="right" :src="require('./images/blur.png')" fit="contain" />
        </div>
        <div class="company">
          {{item.fundManagerName}}
        </div>
        <div class="data">
          <div class="top">
            <div class="left">
              {{ item.newestValue ||'--'}}
            </div>
            <div class="right">
              {{ item.startInvestAmtNew }}万
            </div>
          </div>
          <div class="bottom">
            <div class="left">
              <el-image class="A" :src="require('./images/jing.png')" fit="contain" />
              <div class="B">
                最新净值
              </div>
              <div class="B">
                |
              </div>
              <div class="C">
                {{ item.enddate }}
              </div>
            </div>
            <div class="right">
              <el-image class="A" :src="require('./images/qi.png')" fit="contain" />
              <div class="B">
                起投金额
              </div>
            </div>
          </div>
        </div>
        <div :class="['btn', {'disable stop-buy':item.buyStatus === '0'}]" @click="contact(item)">
          预约咨询
        </div>
      </div>
    </div>
    <div v-show="total" class="page-bar">
      <el-pagination popper-class="page-class" background :page-sizes="[9,18]" :page-size="page.pageSize" layout="prev, pager, next,sizes" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
  <dia-log :dialog-visible="dialogVisible" @closeDialog="closeDialog" />

  <Models :btn-text="btnText" :showmodel="showmodel" @cancels="cancels" @showdelte="cancels" @confirms="confirms" @showmodels="showmodel=false">
    <div class="modal-box">
      <div class="title">
        {{ modalData.modalTitle }}
      </div>
      <div class="content">
        {{ modalData.content }}
      </div>
    </div>
  </Models>
</div>
</template>

<script>
import {
  highFinancial,
  prodInfoAdd
} from "@/api/highFinancial";
import {
  accreditedInvestor
} from '@/api/myAcc'
import {
  selectDictLabel
} from "@/utils/dicts";
import DiaLog from '@/components/dia-log/dia-log'
import Models from "@/components/Models";
import {
  getDicts
} from '@/api/dict.js'
import {
  getImgs
} from '@/api/other/index.js'
export default {
  name: "HighFinancial",
  components: {
    DiaLog,
    Models
  },
  data() {
    return {
      dialogVisible: false,
      showmodel: false,
      total: 0,
      sys_fund_risklevel: [],
      sys_fund_type: [],
      dataList: [],
      userStatus: null,
      banners: [],
      boxA: [{
          id: 0,
          url: require('./images/A.png'),
          title: "专属客户服务",
          subTitle: "一对一的私人理财咨询"

        },
        {
          id: 1,
          url: require('./images/B.png'),
          title: "专享财富沙龙",
          subTitle: "分享各类财富理念、财经资讯、线下交流"

        },
        {
          id: 2,
          url: require('./images/C.png'),
          title: "专享定制服务",
          subTitle: "根据高净值客户的目标和风险的需求，定制整体解决方案"

        },
        {
          id: 3,
          url: require('./images/D.png'),
          title: "专属配置服务",
          subTitle: "优质产品甄选，为客户提供更多的投资选择"

        },

      ],
      boxB: [{
          id: 0,
          url: require('./images/E.png'),
          title: "生日好礼",

        },
        {
          id: 1,
          url: require('./images/F.png'),
          title: "节日礼包",

        },
        {
          id: 2,
          url: require('./images/G.png'),
          title: "年度答谢",

        },
        {
          id: 3,
          url: require('./images/H.png'),
          title: "配置建议",

        },
        {
          id: 4,
          url: require('./images/I.png'),
          title: "市场解读",

        },
        {
          id: 5,
          url: require('./images/J.png'),
          title: "投资健诊",

        },
        {
          id: 6,
          url: require('./images/K.png'),
          title: "基金经理交流",

        },
        {
          id: 7,
          url: require('./images/L.png'),
          title: "报告会邀请",

        },

      ],
      boxC: [{
          id: 0,
          url: require('./images/1.png'),
          nextFlag: require('./images/nextFlag.svg'),
          title: "第一步：预约咨询",
          subTitle: "可点击“预约咨询”或者致电众惠基金客服热线：0851-82209888，众惠基金将为您提供“一对一理财专属服务”。"

        },
        {
          id: 1,
          url: require('./images/2.png'),
          nextFlag: require('./images/nextFlag.svg'),
          title: "第二步：合格投资者适当性匹配",
          subTitle: "签署关于产品风险等级与投资者风险承受能力匹配情况的说明文件。"

        },
        {
          id: 2,
          url: require('./images/3.png'),
          nextFlag: require('./images/nextFlag.svg'),
          title: "第三步：签约打款",
          subTitle: "签署风险告知书、产品合同等文件。将产品认（申）购款汇至众惠基金高端理财产品募集专户。"

        },
        {
          id: 3,
          url: require('./images/4.png'),
          nextFlag: require('./images/nextFlag.svg'),
          title: "第四步：回访确认",
          subTitle: "签署《产品合同》并交纳认（申）购款项后，投资者即进入24小时的冷静期。冷静期期满后，众惠基金将以适当方式进行回访确认。"
        },
        {
          id: 4,
          url: require('./images/5.png'),
          nextFlag: "",
          title: "第五步：订单确认",
          subTitle: "登录“众惠基金”客户端或官网，进行订单信息确认。"
        }
      ],
      page: {
        pageNum: 1,
        pageSize: 9
      }
    }
  },
  computed: {
    modalData() {
      let obj = {
        modalTitle: "未登录",
        content: "请优先登录，进行投资者资格确认后享受高端理财服务。"
      }
      const status = this.userStatus;
      if (status == 0) {
        // 未登录
      } else if (status == 1) {
        // 未开户
        obj = {
          modalTitle: "未开户",
          content: "当前未开户，无法享有高端理财服务，请前往开户。"
        }
      }
      return obj;
    },

    btnText() {
      let obj = {
        cancel: "取消",
        comfirm: "去登录",
      }
      const status = this.userStatus;
      if (status == 0) {
        // 未登录
      } else if (status == 1) {
        // 未开户
        obj = {
          cancel: "取消",
          comfirm: "去开户",
        }
      }
      return obj;
    },
  },
  created() {
    this.getBanners();
    this.initDicts().then(() => {
      this.highFinancial();
    })
    if(!this.isTradableNew()){
      this.$alert('您暂未进行合格投资者认证或风险测评，请扫描右上角APP下载，于APP内操作！', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.replace({
             path: '/'
          })
          }
        });
    }
    
  },
  mounted() {
    this.userComfirm();
  },
  methods: {
    // 获取轮播图
    getBanners() {
      getDicts('sys_img_user_site').then(result => {
        let imgUseSite = (result.data.find(m => m.dictLabel === 'web端高端理财轮播') || {}).dictValue
        if (imgUseSite) {
          getImgs('1', imgUseSite).then(imgResult => {
            this.banners = Array.from(imgResult.data, m => {
              return {
                title: m.imgName,
                href: m.imgLinkedUrl,
                src: m.imgUrl,
                id: m.imgId
              }
            })
          })
        }
      })
    },
    // 合格投资者确认
    userComfirm() {
      const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
      if (!customerInfo) {
        return;
      }
      const {
        clientHgFlag
      } = customerInfo;
      // clientHgFlag : 0:合格  1：不合格
      if (clientHgFlag != 0) {
        // 不合格
        this.dialogVisible = true;
      }

    },
    // 
    confirms() {
      const status = this.userStatus;
      if (status == 0) {
        // 未登录，跳到登录页面
        this.$router.replace({
          path: '/login'
        })

      } else if (status == 1) {
        // 未开户，调到开户页面
        this.$router.replace({
          path: '/openAccount'
        })

      }
      this.showmodel = false;
    },
    cancels() {
      this.showmodel = false;
    },
    closeDialog(payload) {
      const {
        type
      } = payload;

      if (type == 1) {
        // 修改合格投资者确认字段   clientHgFlag
        // 请求接口
        this.$alert('<div  class="crcode-box1">合格投资者确认需签署合格投资确认文件，请扫描右上角APP下载二维码，在APP内操作！ </div>'
         , '提示', {
          dangerouslyUseHTMLString: true
        });
        // accreditedInvestor({}).then(res => {
        //   const {
        //     code
        //   } = res;
        //   if (code == 200) {
        //     let customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
        //     customerInfo.clientHgFlag = "0";
        //     window.localStorage.setItem('customerInfo', JSON.stringify(customerInfo))
        //   }
        // })
      }
      this.dialogVisible = false;
    },
    initDicts() {
      this.getDicts('sys_fund_risklevel').then(r1 => {
        this.sys_fund_risklevel = r1.data;
      });
      return this.getDicts('sys_fund_type').then(r1 => {
        this.sys_fund_type = r1.data;
      });
    },
    formatter(val, options) {
      return selectDictLabel(val, options);
    },

    contact(item) {
      if(item.buyStatus === '0') {
        return;
      }
      const {
        fundCode
      } = item;
      const customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
      // 是否登录
      if (!customerInfo) {
        this.userStatus = 0;
        this.showmodel = true;
        return;
      }

      // 是否开户
      const {
        acctFlag
      } = customerInfo;
      if (acctFlag != 1) {
        // 未开户
        this.userStatus = 1;
        this.showmodel = true;
        return;
      }

      //  弹窗确认
      this.$confirm('确认要进行预约吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const {
          userId: customId
        } = customerInfo;
        let params = {
          customId,
          idOrCode: fundCode,
          bookingChannel: "1" // 预约渠道：0=app, 1=web, 2=web后台管理
        };
        prodInfoAdd(params).then(res => {
          const {
            code,
            msg
          } = res;
          if (code == 200) {
            this.$message.success('预约成功!');
          }
        })
      }).catch(() => {})

    },
    highFinancial() {
      highFinancial({
        //查询传参
        ...this.page,
        privateFlag: "1" //私募标识 0：公募 1：私募
      }).then(r => {
        const {
          code,
          data: {
            data = [],
            total
          }
        } = r;
        if (code == 200) {
          data.forEach(m => {
            m.risk = this.formatter(m.ofundRisklevel, this.sys_fund_risklevel)
            m.fundType = this.formatter(m.ofundType, this.sys_fund_type)
            m.enddate = (m.enddate || '').$formatDate()
            m.incomeYears = parseFloat((m.incomeYear || '').replace('%','') || 0)
          })
          this.dataList = data;
          this.total = total;
        }

      })
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.page.pageNum = 1;
      this.page.pageSize = val;
      this.highFinancial();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page.pageNum = val;
      this.highFinancial();
    }
  },

};
</script>

<style lang="scss" scoped>
.container {
  .banner {
    .img {
      display: block;
      margin: auto;
    }
  }

  .title {
    margin-top: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    color: #1F1F1F;
    text-align: center;
  }

  .box-sub-title {
    display: flex;
    justify-content: center;

    .sub-title {
      margin-top: 24px;
      width: 1200px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #A5A5A5;
    }
  }

  .boxA {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .boxA-item {
      width: 47%;
      padding-left: 3%;
      display: flex;
      padding-bottom: 44px;

      .left {
        padding: 10px;
        width: 72px;
        height: 72px;
      }

      .right {
        margin-left: 48px;

        .top {
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          color: #18191F;
        }

        .bottom {
          margin-top: 48px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #6B6B6B;
        }
      }
    }

  }

  .boxB {
    background-color: #2d2a26;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px 0 113px 0;

    .title {
      width: 100%;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 64px;
      text-align: center;
      font-feature-settings: 'salt'on, 'liga'off;
      color: #FFFFFF;
    }

    .boxB-list {
      margin: 38px auto 0;
      width: 1440px;
      display: flex;
      flex-wrap: wrap;

      .boxB-item {
        padding: 27px 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        width: 33%;

        .left {
          width: 36px;
          height: 32px;
        }

        .right {
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          font-feature-settings: 'salt'on, 'liga'off;
          color: #FFF5EA;
          margin: 0px 24px;
        }
      }
    }

  }

  .private-trade {
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 2em;

    .title-box {
      background: #FFFFFF;
      box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 64px;
        text-align: center;
        font-feature-settings: 'salt'on, 'liga'off;
        color: #1F1F1F;
      }

      .box-list {
        margin-top: 32px;
        padding: 20px;
        display: flex;

        .box-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 6px;

          .top {
            display: flex;
            align-items: center;

            .A {
              width: 30px;
              height: 64px;
            }

            .B {
              margin-left: 10px;
              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 1.5em;
              color: #6B6B6B;

            }

            .C {
              margin-left: 100px;
              width: 18px;
              height: 30px;
            }
          }

          .bottom {
            width: 211px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #A5A5A5;
          }
        }
      }

    }
  }

  .hight {
    background: #2d2a26;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 116px;

    .title {
      margin-top: 60px;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 64px;
      text-align: center;
      font-feature-settings: 'salt'on, 'liga'off;
      color: #FFFFFF;
    }

    .list-box {
      margin-top: 40px;
      padding: 0 128px 116px;
      width: 1570px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;

      .box-item {
        margin: 12px 30px;
        width: 374px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 1px 33px 42px;
        box-sizing: border-box;

        .risk {
          margin-top: 41px;
          display: flex;

          .A {
            padding: 7px 18px;
            background: #FFFFFF;
            border: 0.6px solid #FA2832;
            border-radius: 4px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            font-feature-settings: 'salt'on, 'liga'off;
            color: #FA2832;
          }

          .B {
            margin-left: 14px;
            padding: 7px 18px;
            background: #FFFFFF;
            border: 0.6px solid #CE9B63;
            border-radius: 4px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            text-align: center;
            font-feature-settings: 'salt'on, 'liga'off;
            /* 主题色/黄 */
            color: #CE9B63;

          }

        }

        .code {
          margin-top: 18px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          font-feature-settings: 'salt'on, 'liga'off;
          color: #BDC0CB;

        }

        .name {
          margin-top: 7px;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 32px;
          font-feature-settings: 'salt'on, 'liga'off;
          color: #25293D;
        }

        .recent {
          margin-top: 6px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          font-feature-settings: 'salt'on, 'liga'off;
          color: #8691A8;

        }

        .img-box {
          margin-top: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            font-style: normal;
            font-weight: 600;
            font-size: 35px;
            line-height: 26px;
            font-feature-settings: 'salt'on, 'liga'off;

            &.green {
              color: #02D33D;
            }

            &.red {
              color: #D43F3F;
            }

          }

          .right {
            width: 128px;
            height: 68px;
          }
        }

        .company {
          margin-top: 36px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 14px;
          color: #BDC0CB;
          margin: 9px 0px;
        }

        .data {

          .top {
            display: flex;
            justify-content: space-between;

            .left {
              font-style: normal;
              font-weight: 800;
              font-size: 20px;
              line-height: 18px;
              font-feature-settings: 'salt'on, 'liga'off;
              color: #18191F;
            }

            .right {
              font-style: normal;
              font-weight: 800;
              font-size: 20px;
              line-height: 18px;
              font-feature-settings: 'salt'on, 'liga'off;
              color: #18191F;
            }
          }

          .bottom {
            margin-top: 6px;
            display: flex;
            justify-content: space-between;

            .left {
              display: flex;
              align-items: center;

              .A {
                width: 16px;
                height: 16px;

              }

              .B {
                margin-left: 6px;
                font-style: normal;
                font-weight: 290;
                font-size: 14px;
                line-height: 26px;
                font-feature-settings: 'salt'on, 'liga'off;
                color: #8691A8;
              }

              .C {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 14px;
                text-align: right;
                font-feature-settings: 'salt'on, 'liga'off;
                color: #BDC0CB;
                margin: 0px 2px;
              }
            }

            .right {

              display: flex;
              align-items: center;

              .A {
                width: 16px;
                height: 16px;
              }

              .B {
                margin-left: 6px;
                font-style: normal;
                font-weight: 290;
                font-size: 14px;
                line-height: 26px;
                font-feature-settings: 'salt'on, 'liga'off;
                color: #8691A8;
              }
            }
          }

        }

        .btn {
          margin-top: 58px;
          padding: 13px 57px;
          display: inline-block;
          background: linear-gradient(109.88deg, #F7D9B7 -3.02%, #EABF96 98.27%);
          border-radius: 50px;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: #CE9B63;

        }

      }
    }

    .page-bar {
      width: 1570px;
      display: flex;
      justify-content: flex-end;

      .page-class {
        /deep/.el-scrollbar__view .el-select-dropdown__list {
          background-color: #120c10;
          color: #fff;
        }
      }

      /deep/.el-pagination button:disabled {
        color: #24251D;
      }

      /deep/.el-pagination.is-background .el-pager li:not(.disabled) {
        color: #fff;
      }

      /deep/.el-pagination.is-background .btn-next,
      /deep/.el-pagination.is-background .btn-prev,
      /deep/.el-pagination.is-background .el-pager li {
        background-color: #1b1519;
        border: 1px solid #24251d;
        color: #fff;
      }

      /deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
        color: #fff;
      }

      /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #DEB87B;
        border-radius: 100%;
      }

      /deep/.el-pagination__sizes {

        .el-input__inner {
          background-color: #120c10;
          color: #fff;
        }
      }

    }

  }

  .modal-box {
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 24px;
      color: #000000;
      text-align: left;
    }

    .content {
      margin: 24px 10px 0;
      font-style: normal;
      font-weight: 290;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
    }
  }

}
</style>
